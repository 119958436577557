<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
            <template v-slot:main>
              <b-form-group id="input-group-teacher_mentor1">
                <label for="input-teacher_mentor1">Dosen Pembimbing 1: </label>
                <b-form-input id="input-teacher_mentor1" v-model="form.teacher_mentor1" placeholder="Dosen Pembimbing 1"></b-form-input>
                <small class="text-danger">{{ error.teacher_mentor1 }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher_mentor2">
                <label for="input-teacher_mentor2">Dosen Pembimbing 2: </label>
                <b-form-input id="input-teacher_mentor2" v-model="form.teacher_mentor2" placeholder="Dosen Pembimbing 2"></b-form-input>
                <small class="text-danger">{{ error.teacher_mentor2 }}</small>
              </b-form-group>

              <b-form-group id="input-group-title">
                <label for="input-title">Judul / Topik Penelitian:</label>
                <b-form-input id="input-title" v-model="form.title" placeholder="Judul"></b-form-input>
                <small class="text-danger">{{ error.title }}</small>
              </b-form-group>

              <b-form-group id="input-group-validated_tool_name">
                <label for="input-validated_tool_name">Nama Alat / Media Yang Akan Divalidasi: </label>
                <b-form-input id="input-validated_tool_name" v-model="form.validated_tool_name" placeholder="Nama Alat / Media Yang Akan Divalidasi"></b-form-input>
                <small class="text-danger">{{ error.validated_tool_name }}</small>
              </b-form-group>
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button type="button" class="ml-2" variant="default" @click="$router.push('/letters/validation-approval-form/list')"> Batal </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components: {
    MainForm,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        semester_id: "",
        teacher_mentor1: "",
        teacher_mentor1: "",
        title: "",
        validated_tool_name: "",
        sign_image: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
    };
  },
  methods: {
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      // if (
      //   this.form.hasOwnProperty("interest") == false ||
      //   this.form.interest == ""
      // ) {
      //   this.error.interest = "Kolom kepeminatan tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.interest = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution1") == false ||
      //   this.form.institution1 == ""
      // ) {
      //   this.error.institution1 = "Kolom instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address1") == false ||
      //   this.form.institution_address1 == ""
      // ) {
      //   this.error.institution_address1 =
      //     "Kolom alamat instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution2") == false ||
      //   this.form.institution2 == ""
      // ) {
      //   this.error.institution2 = "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution2 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address2") == false ||
      //   this.form.institution_address2 == ""
      // ) {
      //   this.error.institution_address2 =
      //     "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address2 = "";
      // }
      // if (this.purpose == "add") {
      //   if (this.form.hasOwnProperty("sign_image") == false || this.form.sign_image == "") {
      //     this.error.sign_image = "Gambat TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue");
      //     console.log("send emit");
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse");
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
      if (this.validationCheck() == false) {
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("Validation Error");
      }

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors);
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/letters/validation-approval-form/list");
      }
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getSemesterActive();
  },
  computed: {
    photo() {
      return this.current_photo == null ? this.default_photo : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
